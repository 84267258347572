import React from "react";
import Cookies from "js-cookie";

export const OutOfStock = ({ data, setData }) => {
  const downloadCSV = (e) => {
    e.preventDefault();
    const csv = data.map((row) => Object.values(row).join(",")).join("\n");
    const csvFile = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvFile);
    const a = document.createElement("a");
    a.href = url;
    const today = new Date().toISOString().split("T")[0];
    a.download = `${today} Out of Stock.csv`;
    a.click();
  };

  const updateWoocommerce = async (e) => {
    e.preventDefault();

    const response = await fetch(`api/stock/update-product`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": Cookies.get("auth-token"),
      },
      body: JSON.stringify({ ids: data.map((row) => row.id) }),
    });
    const json = await response.json();
    if (json.success) {
      e.preventDefault();
    } else {
      alert(json.error);
    }
  };

  return (
    <div className="delete-confirmation edit-order-report">
      <div
        className="delete-confirmation-dialog  edit-order-report-dialoge"
        style={{ width: "750px" }}
      >
        <form className="addrecord-form">
          <div className="product-list-pc invoice-details-container ">
            <div className="scroll-container-pc">
              <table>
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>ID</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((product, index) => (
                    <tr key={index}>
                      <td style={{ width: "200px" }}>{product.SKU}</td>
                      <td>{product.id}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="buttons edit-order-save-btns">
            <button
              className="btn btn-outline-success edit-order-save-btn"
              onClick={downloadCSV}
            >
              Download CSV
            </button>
            <button
              className="btn btn-outline-success edit-order-save-btn"
              onClick={updateWoocommerce}
            >
              Update Woocommerce
            </button>
            <button
              className="btn btn-outline-secondary edit-order-save-btn"
              onClick={() => setData([])}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
